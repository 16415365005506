import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { RunSegment, ScheduledRun, WeeklySchedule } from '../models/schedule.model';

// Models
@Injectable({ providedIn: 'root' })
export class SchedulingService {

    resourceUrl: string = environment.apiEndpoint;

    constructor(private http: HttpClient) {
    }

    getWeeklySchedules(organizationId: string): Observable<WeeklySchedule[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules`;
        return this.http.get<WeeklySchedule[]>(url);
    }


    getWeeklyScheduleById(organizationId: string, weeklyScheduleId: string): Observable<WeeklySchedule> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${weeklyScheduleId}`;
        return this.http.get<WeeklySchedule>(url);
    }

    generateNextWeeklySchedule(organizationId: string,): Observable<WeeklySchedule> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/generate-weekly-schedule`;
        console.log('generateNextWeeklhySchedule service..')
        return this.http.post<WeeklySchedule>(url, {});
    }

    getScheduledRuns(organizationId: string, weeklyScheduleId: string): Observable<ScheduledRun[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${weeklyScheduleId}/scheduled-runs`;
        // console.log(url)
        return this.http.get<ScheduledRun[]>(url);
    }

    updateScheduledRun(organizationId: string, scheduledRun: ScheduledRun): Observable<ScheduledRun> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${scheduledRun.weeklyScheduleId}/scheduled-runs/${scheduledRun.id}`;
        // console.log(url)
        return this.http.put<ScheduledRun>(url, scheduledRun);
    }

    createScheduledRun(organizationId: string, scheduledRun: ScheduledRun): Observable<ScheduledRun> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${scheduledRun.weeklyScheduleId}/scheduled-runs`;
        // console.log(url)
        return this.http.post<ScheduledRun>(url, scheduledRun);
    }

    // Run Segments
    updateRunSegment(organizationId: string, weeklyScheduleId: number, runSegment: RunSegment): Observable<RunSegment> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${weeklyScheduleId}/scheduled-runs/${runSegment.scheduledRunId}/run-segments/${runSegment.id}`;
        // console.log(url)
        return this.http.put<RunSegment>(url, runSegment);
    }

    createRunSegment(organizationId: string, weeklyScheduleId: number, runSegment: RunSegment): Observable<RunSegment> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${weeklyScheduleId}/scheduled-runs/${runSegment.scheduledRunId}/run-segments`;
        // console.log(url)
        return this.http.post<RunSegment>(url, runSegment);
    }

    deleteRunSegment(organizationId: string, weeklyScheduleId: number, scheduledRunId: number, id: number): Observable<number> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${weeklyScheduleId}/scheduled-runs/${scheduledRunId}/run-segments/${id}`;
        // console.log(url)
        return this.http.delete<number>(url);
    }

    sendRunSegmentNotifications(organizationId: string, weeklyScheduleId: number, runSegments: RunSegment[]): Observable<RunSegment[]> {
        const url = `${this.resourceUrl}/organizations/${organizationId}/schedule/weekly-schedules/${weeklyScheduleId}/send-run-segment-notifications`;
        // console.log(url)
        return this.http.post<RunSegment[]>(url, runSegments);
    }
    confirmRunSegmentNotification(runSegmentNotificationId: string, driverNumber: string): Observable<any> {
        const url = `${this.resourceUrl}/public/schedule/confirm-run-segment-notification`;
        const request = {
            runSegmentNotificationId: runSegmentNotificationId,
            driverNumber: driverNumber
        }
        return this.http.post<any>(url, request);
    }

    getDriverSchedule(weeklyScheduleId: string, employeeId: string, passcode: string): Observable<any> {
        const url = `${environment.apiUnauthenticatedEndpoint}/schedule/weekly-schedules/${weeklyScheduleId}/get-driver-schedule`;
        const request = {
            employeeId, passcode
        }
        return this.http.post<any>(url, request);
    }

    confirmDriverSchedule(weeklyScheduleId: string, employeeId: string, runSegments: number[], passcode: string): Observable<any> {
        const url = `${environment.apiUnauthenticatedEndpoint}/schedule/weekly-schedules/${weeklyScheduleId}/confirm-driver-schedule`;
        const request = { runSegments, employeeId, passcode }
        return this.http.post<any>(url, request);
    }
}
